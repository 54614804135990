import React from 'react'
import {
    Book,
    GoogleDocs,
    List,
    PiggyBank,
    ReportColumns,
    Wrench,
    Car,
    Calendar,
    StatsReport,
} from 'iconoir-react'
export const defaultPlanningView = localStorage.getItem('planningView')
export const navMenu = [
    {
        id: 'Général',
        menu: [
            {
                id: 'Transports',
                icon: <GoogleDocs />,
                url: '/transport',
                fatherUrl: '/transport',
                subMenu: [],
            },
            {
                id: 'Séries',
                icon: <List />,
                url: '/series',
                fatherUrl: '/series',
                subMenu: [],
            },
            {
                id: 'Régulation',
                icon: <Calendar />,
                url: `${
                    defaultPlanningView === 'Dashboard'
                        ? '/regulation/dashboard'
                        : defaultPlanningView === 'Mélangé'
                        ? '/regulation/melange'
                        : '/regulation/trie'
                }`,
                fatherUrl: '/regulation',
                openSubMenuByDefault: true,
                subMenu: [
                    {
                        id: 'Trié',
                        url: '/regulation/trie',
                    },
                    {
                        id: 'Mélangé',
                        url: '/regulation/melange',
                    },
                    {
                        id: 'Dashboard',
                        url: '/regulation/dashboard',
                    },
                ],
            },
            {
                id: 'Ressources',
                icon: <ReportColumns />,
                url: '/ressources',
                fatherUrl: '/ressources',
                subMenu: [
                    {
                        id: 'Répertoire',
                        url: '/ressources/repertoire',
                    },
                    {
                        id: 'Utilisateurs',
                        url: '/ressources/utilisateurs',
                    },
                    {
                        id: 'Équipes',
                        url: '/ressources/equipes',
                    },
                    {
                        id: 'Véhicules',
                        url: '/ressources/vehicules',
                    },
                    {
                        id: 'Partenaires',
                        url: '/ressources/partenaires',
                    },
                    {
                        id: 'Caisses',
                        url: '/ressources/caisses',
                    },
                    {
                        id: 'Prescripteurs',
                        url: '/ressources/prescripteurs',
                    },
                    {
                        id: 'Complémentaires',
                        url: '/ressources/complementaires',
                    },
                ],
            },
            {
                id: 'Facturation',
                icon: <PiggyBank />,
                url: '/facturation',
                fatherUrl: '/facturation',
                subMenu: [
                    {
                        id: 'Factures sanitaires',
                        isCpam: true,
                        url: '/facturation/sanitaires',
                    },
                    {
                        id: 'Gestions des lots',
                        isCpam: true,
                        url: '/facturation/lots',
                    },
                    {
                        id: 'Télétrans. NOEMIE',
                        isCpam: true,
                        url: '/facturation/noemie',
                    },

                    {
                        id: 'Factures libres',
                        url: '/facturation/factures',
                    },
                    {
                        id: 'Établissements',
                        url: '/facturation/etablissements',
                    },
                ],
            },
        ],
    },
    {
        id: 'Qualité',
        menu: [
            {
                id: 'Paramètres',
                icon: <Wrench />,
                url: '/parametres',
                fatherUrl: '/parametres',
                subMenu: [],
            },
            {
                id: 'Statistiques',
                icon: <StatsReport />,
                url: '/statistiques',
                fatherUrl: '/statistiques',
                subMenu: [],
            },
            {
                id: 'Documentation',
                icon: <Book />,
                url: 'https://help.heroad.io/',
                fatherUrl: 'https://help.heroad.io/',
                externalLink: true,
                subMenu: [],
            },
        ],
    },
    {
        id: 'Changer de vue',
        menu: [
            {
                id: 'Mode route',
                icon: <Car />,
                url: '/chauffeur',
                fatherUrl: '/chauffeur',
                subMenu: [],
            },
        ],
    },
]

export default navMenu
