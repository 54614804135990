import React from 'react'
import {
    Archive,
    Copy,
    EyeClosed,
    Eye,
    List,
    Minus,
    PageEdit,
    Plus,
    ShareAndroid,
    Trash,
} from 'iconoir-react'

const DefaultOptions = ({
    rowId,
    handleModalDelete,
    handleModalArchived,
    isArchivedSeries,
    handleModalShare,
    historyPush,
    historyPushCopy,
    historyPushConsulting,
    optionsRef,
    view,
    historyPushUpdate,
    isSeriesRun,
    handleModalAddToSeries,
    isPartnerRun,
    handleHideTeam,
    isHidedTeam,
}) => {
    return (
        <div
            className="relative z-50 flex w-32 min-w-full cursor-pointer flex-col rounded-md border bg-white text-sm"
            ref={optionsRef}
        >
            {handleModalShare && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 text-blue-500 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleModalShare(rowId)
                    }}
                >
                    <ShareAndroid />
                    <div className="truncate">
                        {isPartnerRun ? 'Retirer le partage' : 'Partager'}
                    </div>
                </div>
            )}
            {historyPushCopy && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        historyPushCopy(rowId)
                    }}
                >
                    <Copy />
                    <div className="truncate">Copier</div>
                </div>
            )}
            {historyPushConsulting && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        historyPushConsulting(rowId)
                    }}
                >
                    <List />
                    <div className="truncate">Consulter</div>
                </div>
            )}
            {historyPushUpdate && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        historyPushUpdate(rowId)
                    }}
                >
                    <PageEdit />
                    <div className="truncate">Modifier</div>
                </div>
            )}
            {handleModalAddToSeries && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleModalAddToSeries(rowId)
                    }}
                >
                    {isSeriesRun ? <Minus /> : <Plus />}
                    <div className="truncate">Série</div>
                </div>
            )}
            {handleModalArchived && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleModalArchived(rowId)
                    }}
                >
                    <Archive />
                    <div className="truncate">
                        {isArchivedSeries ? 'Désarchiver' : 'Archiver'}
                    </div>
                </div>
            )}
            {handleModalDelete && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 text-red-500 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleModalDelete(rowId)
                    }}
                >
                    <Trash />
                    <div className="truncate">Supprimer</div>
                </div>
            )}

            {view && (
                <div
                    className="flex  items-center   px-2 py-1  hover:bg-gray-200 hover:font-bold "
                    onClick={(e) => {
                        e.stopPropagation()
                        historyPush(rowId)
                    }}
                >
                    <i className="fa fa-eye" />
                    <div className="truncate">Consulter</div>
                </div>
            )}

            {handleHideTeam && (
                <div
                    className="grid-cols-options-2 grid items-center truncate px-2 py-1 hover:bg-gray-200 hover:font-bold"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleHideTeam(rowId)
                    }}
                >
                    {isHidedTeam ? <Eye /> : <EyeClosed />}
                    <div className="truncate">
                        {isHidedTeam ? 'Afficher' : 'Cacher'}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DefaultOptions
