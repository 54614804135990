import Loader from '../../../../Commons/Loaders/Loader'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { SERIES_ACTIONS } from '../../../../../services/Reducers/SeriesReducer'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../../../services/Reducers/AddressAutoCompleteReducer'
import FetchSeriesInformations, {
    fetchPractitioners,
} from '../../../../../services/fetchSeriesInformations'
import GlobalRunInformations from '../GlobalRunInformations'
import SeriesConfigurator from '../SeriesConfigurator'
import SERIES from '../../../../../services/series'

import * as dayjs from 'dayjs'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { findRunTypeFromRunToInsert } from '../../../../../tools/Utility'
import ActionSeriesModal from '../../../../Modal/ActionSeriesModal'
import {
    DateReducer,
    getDateInitialState,
    getDateInitialStateFormDate,
} from '../../../../../services/Reducers/DateReducer'
import SecondaryButton from '../../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import { Car, Notes, PiggyBank, User, Wrench } from 'iconoir-react'

import PatientFormContent from '../../../Patient/PatientFormContent'
import {
    getSeriesGlobalInitialState,
    SeriesGlobalFormReducer,
} from '../SeriesGlobalReducer'
import { handlePatientForm } from '../../../Patient/handlePatientForm'
import PrescriptionForm from '../../../Regulation/Forms/PrescriptionForm'
import SeriesCpamInvoiceForm from '../Forms/SeriesCpamInvoiceForm'
import {
    fetchHealthComplementaries,
    fetchPayingCenters,
} from '../../../Regulation/RunTabs'
import { PATIENT_FORM_ACTIONS } from '../../../Regulation/Reducers/PatientFormReducer'
import { checkAddPrescriptionToSeries } from '../../../Regulation/FormHandlers/handleInvoiceForm'
import { getDateReducerFormatForApi } from '../../../../../services/DateService'
import API from '../../../../../services/api'
import { mergeDateAndTimeAndTransformToTimeStamp } from '../../../../../services/dateUtils'

const SeriesForm = ({
    date,
    setOpenSeriesManageModal,
    setOpenNewSeriesManageModal,
    fromModal,
    seriesId,
    reRender,
    fetchRunsSeriesList,
}) => {
    const location = useLocation()
    let { id } = useParams()
    if (fromModal && seriesId !== 0) {
        id = seriesId
    }

    const isUserAbleToInvoice = JSON.parse(localStorage.getItem('isInvoicer'))

    const history = useNavigate()

    const tab = location.search.includes('?Series_tab=')
        ? location.search.split('?Series_tab=')[1]
        : null

    const [activeTab, setActiveTab] = useState(tab ? tab : 'Configuration')

    const [global, dispatch] = useReducer(
        SeriesGlobalFormReducer,
        { date },
        getSeriesGlobalInitialState
    )

    const series = global.series
    const patientForm = global.patientForm
    const prescriptionForm = global.prescriptionForm
    const seriesCpamInvoiceForm = global.seriesCpamInvoiceForm

    const [pickUpAddress, pickUpDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )
    const [depositAddress, depositDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const [datePatientInput, datePatientDispatch] = useReducer(
        DateReducer,
        [],
        getDateInitialState
    )
    const [prsDateInput, prsDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [realDateInput, realDateDispatch] = useReducer(
        DateReducer,
        { date },
        getDateInitialStateFormDate
    )
    const [workAccidentDate, workAccidentDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [priorApprovalDate, priorApprovalDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [dateMaternity, dateMaternityDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const inputPickUp = useRef()
    const inputDeposit = useRef()

    useEffect(() => {
        FetchSeriesInformations.getSeries(
            id,
            series,
            dispatch,
            pickUpDispatch,
            pickUpAddress,
            depositAddress,
            depositDispatch,
            datePatientDispatch,
            prsDateDispatch,
            dateMaternityDispatch,
            workAccidentDateDispatch,
            priorApprovalDateDispatch
        ).then((response) => {})
        fetchHealthComplementaries(dispatch).then(() => {})
        fetchPayingCenters(dispatch).then(() => {})
    }, [])

    const handleForm = async () => {
        toast.dismiss()
        const teamsRunsOnSameHours = await checkErrorsTeamsRunsOnSameHours()

        if (!checkErrors() && !teamsRunsOnSameHours) {
            if (series.id) {
                // it's an update, so ask verification
                dispatch({
                    type: SERIES_ACTIONS.SET_OPEN_VALIDATION_MODAL,
                    payload: true,
                })
            } else {
                await handleCreateOrPutSeries()
            }
        }
    }

    const checkErrorsTeamsRunsOnSameHours = async () => {
        if (!series.runsToInsert.some((r) => r.defaultMasterRunId)) {
            return false
        }

        const isAnUpdate = !!series.id

        const data = await API.Series.checkTeamsRunOnSameHours({
            runsToInsert: series.runsToInsert.map((run) => {
                return {
                    key: run.key,
                    status: run.status,
                    returnStatus: run.returnStatus,
                    isAnUpdate: isAnUpdate,
                    returnRunSharedAndGiven:
                        run.returnRunSharedAndGiven || false,
                    id: run.id,
                    returnRunId: run.returnRunId,
                    date: Date.parse(run.date) / 1000,
                    dateString: run.dateString,
                    departureTime: mergeDateAndTimeAndTransformToTimeStamp(
                        run.date,
                        run.departureTime
                    ),
                    arrivingTime: mergeDateAndTimeAndTransformToTimeStamp(
                        run.date,
                        run.arrivingTime
                    ),
                    returnTime: run.returnTime
                        ? mergeDateAndTimeAndTransformToTimeStamp(
                              run.date,
                              run.returnTime
                          )
                        : null,
                    isReturnPath: run.isReturnPath,
                    isRoundTrip: run.isRoundTrip || false,
                    haveIndirectReturn:
                        (run.isRoundTrip && run.haveIndirectReturn) || false,
                    haveDirectReturn: run.haveDirectReturn || false,
                    waitingTime: run.waitingTime ? run.waitingTime : null,
                    defaultMasterRunId: run.defaultMasterRunId
                        ? run.defaultMasterRunId
                        : null,
                    masterRunId: run.masterRunId ? run.masterRunId : null,
                    returnRunDefaultMasterRunId: run.returnRunDefaultMasterRunId
                        ? run.returnRunDefaultMasterRunId
                        : null,
                    returnRunMasterRunId: run.returnRunMasterRunId
                        ? run.returnRunMasterRunId
                        : null,
                    departureOrder: run.departureOrder || null,
                    parentId: run.parentId || null,
                    returnDepartureOrder: run.returnDepartureOrder || null,
                    returnParentId: run.returnParentId || null,
                }
            }),
        })

        if (data.runsToInsert.some((run) => run.error)) {
            dispatch({
                type: SERIES_ACTIONS.SET_RUNS_TO_INSERT,
                payload: series.runsToInsert.map((oldRunToInsert, index) => {
                    if (data.runsToInsert[index].error) {
                        oldRunToInsert.error = data.runsToInsert[index].error
                    }
                    return {
                        ...oldRunToInsert,
                    }
                }),
            })
            toast.error(
                'Des équipes ont des transports sur les mêmes horaires.',
                {
                    autoClose: 8000,
                }
            )
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        // if the user is on the prescription tab fetch the paying centers and practitioners
        if (
            activeTab === 'Prescription' &&
            prescriptionForm.practitionersOptions.length === 0
        ) {
            fetchPayingCenters(dispatch).then(() => {})
            fetchPractitioners(dispatch).then(() => {})
        }
    }, [activeTab])

    const handleCreateOrPutSeries = async () => {
        dispatch({
            type: SERIES_ACTIONS.SET_LOADING,
            payload: true,
        })
        const result = await handlePatientForm(
            patientForm,
            datePatientInput,
            null,
            false
        )
        if (result.success) {
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: result.data,
            })
            prescriptionForm.date = getDateReducerFormatForApi(prsDateInput)
            prescriptionForm.dateMaternity =
                getDateReducerFormatForApi(dateMaternity)
            prescriptionForm.workAccidentDate = getDateReducerFormatForApi(
                workAccidentDate,
                true
            )
            prescriptionForm.priorApprovalDate =
                getDateReducerFormatForApi(priorApprovalDate)

            await SERIES.PostOrPut(
                global,
                pickUpAddress,
                depositAddress,
                history,
                fromModal,
                reRender,
                setOpenSeriesManageModal,
                setOpenNewSeriesManageModal,
                fetchRunsSeriesList,
                prsDateInput,
                datePatientInput
            )
                .then((r) => {
                    if (series.id) {
                        let url = '/series/' + series.id
                        // reload the page to the url
                        window.location.assign(url)
                    } else {
                        history('/series')
                    }
                })
                .catch((e) => {
                    console.log('BUG', e)
                    e.json().then((e) => {
                        toast.error(e['hydra:description'], {
                            autoClose: 8000,
                        })
                    })
                    dispatch({
                        type: SERIES_ACTIONS.SET_LOADING,
                        payload: false,
                    })
                })
        } else {
            dispatch({
                type: SERIES_ACTIONS.SET_LOADING,
                payload: false,
            })
        }
    }

    const checkErrors = () => {
        let errors = false
        if (!series.patient) {
            toast.error('Veuillez renseigner une personne à transporter.', {
                autoClose: 8000,
            })
            errors = true
        }

        let missingPickUpAddresses = false
        let missingDepositAddresses = false
        series.days.forEach((day) => {
            if (day.pickUpLocation && !day.pickUpLocation.city) {
                missingPickUpAddresses = true
            }
            if (day.depositLocation && !day.depositLocation.city) {
                missingDepositAddresses = true
            }
        })

        if (series.runsToInsert.length === 0) {
            toast.error('Veuillez créer des transports.', {
                autoClose: 8000,
            })
            errors = true
        }

        let timesErrors = false
        let indirectReturnTimesErrors = false
        let directReturnTimesErrors = false
        series.runsToInsert.forEach((runToInsert) => {
            let arriving = parseFloat(
                dayjs(runToInsert.arrivingTime).format('HH.mm')
            )
            let departure = parseFloat(
                dayjs(runToInsert.departureTime).format('HH.mm')
            )
            if (arriving <= departure) {
                timesErrors = true
                errors = true
            }

            const runType = findRunTypeFromRunToInsert(runToInsert)

            if (
                runType.value === 4 &&
                runToInsert.returnStatus !== 'canceled'
            ) {
                //indirect return
                let returnTime = parseFloat(
                    dayjs(runToInsert.returnTime).format('HH.mm')
                )
                if (arriving >= returnTime || departure >= returnTime) {
                    indirectReturnTimesErrors = true
                    errors = true
                }
            }

            if (
                runType.value === 3 &&
                runToInsert.returnStatus !== 'canceled'
            ) {
                //direct return
                if (
                    runToInsert.waitingTime === null ||
                    runToInsert.waitingTime === 0
                ) {
                    directReturnTimesErrors = true
                    errors = true
                }
            }
        })
        if (timesErrors) {
            toast.error(
                "Veuillez renseigner des horaires d'arrivées supérieurs aux horaires de départs.",
                {
                    autoClose: 8000,
                }
            )
            errors = true
        }
        if (indirectReturnTimesErrors) {
            toast.error(
                "Veuillez renseigner des horaires de retours supérieurs aux horaires d'arrivées.",
                {
                    autoClose: 8000,
                }
            )
            errors = true
        }
        if (directReturnTimesErrors) {
            toast.error(
                "Veuillez renseigner les temps d'attente pour les retours directs.",
                {
                    autoClose: 8000,
                }
            )
            errors = true
        }
        if (missingPickUpAddresses) {
            toast.error('Veuillez renseigner les villes de départs.', {
                autoClose: 8000,
            })
            errors = true
        }
        if (missingDepositAddresses) {
            toast.error("Veuillez renseigner les villes d'arrivées.", {
                autoClose: 8000,
            })
            errors = true
        }

        let prescription = prescriptionForm.practitioner?.id
            ? prescriptionForm
            : null

        if (prescription) {
            return checkAddPrescriptionToSeries(
                prescription,
                patientForm,
                series.runsToInsert[0].date,
                prsDateInput,
                dateMaternity,
                workAccidentDate,
                priorApprovalDate
            )
        }

        return errors
    }

    const handleCancel = () => {
        if (series.isSetupSeriesView) {
            history(-1)
        } else {
            if (fromModal) {
                setOpenSeriesManageModal(false)
                setOpenNewSeriesManageModal(false)
            } else {
                history(-1)
            }
        }
    }

    const checkSameAddresses = (obj) => {
        return (
            obj.pickUpLocation &&
            obj.depositLocation &&
            obj.pickUpLocation.street === obj.depositLocation.street &&
            obj.pickUpLocation.secondaryStreet ===
                obj.depositLocation.secondaryStreet &&
            obj.pickUpLocation.zipCode === obj.depositLocation.zipCode
        )
    }

    const tabs = [
        {
            name: 'Configuration',
            icon: <Wrench className={'mr-2'} />,
            component: (
                <GlobalRunInformations
                    series={series}
                    dispatch={dispatch}
                    depositAddress={depositAddress}
                    depositDispatch={depositDispatch}
                    pickUpDispatch={pickUpDispatch}
                    inputDeposit={inputDeposit}
                    pickUpAddress={pickUpAddress}
                    inputPickUp={inputPickUp}
                    datePatientDispatch={datePatientDispatch}
                    patientForm={patientForm}
                    datePatientInput={datePatientInput}
                />
            ),
        },
        {
            name: 'Transports',
            icon: <Car className={'mr-2'} />,
            component: (
                <SeriesConfigurator
                    series={series}
                    dispatch={dispatch}
                    depositDispatch={depositDispatch}
                    pickUpDispatch={pickUpDispatch}
                    depositAddress={depositAddress}
                    pickUpAddress={pickUpAddress}
                />
            ),
        },
        {
            name: 'Patient',
            icon: <User className={'mr-2'} />,
            component: (
                <PatientFormContent
                    patient={patientForm}
                    date={datePatientInput}
                    dateDispatch={datePatientDispatch}
                    id={patientForm.id}
                    dispatch={dispatch}
                    handleShowPatientRuns={null}
                />
            ),
        },
        {
            name: 'Prescription',
            icon: <Notes className={'mr-2'} />,
            component: (
                <PrescriptionForm
                    prescription={prescriptionForm}
                    dispatch={dispatch}
                    prsDateInput={prsDateInput}
                    prsDateDispatch={prsDateDispatch}
                    realDateInput={realDateInput}
                    realDateDispatch={realDateDispatch}
                    workAccidentDate={workAccidentDate}
                    workAccidentDateDispatch={workAccidentDateDispatch}
                    priorApprovalDate={priorApprovalDate}
                    priorApprovalDateDispatch={priorApprovalDateDispatch}
                    dateMaternity={dateMaternity}
                    dateMaternityDispatch={dateMaternityDispatch}
                    patientForm={patientForm}
                    birthDate={datePatientInput}
                    practitionerForm={global.practitionerForm}
                    payingCenterForm={global.payingCenterForm}
                />
            ),
        },
        {
            name: 'Facture',
            icon: <PiggyBank className={'mr-2'} />,
            component: (
                <SeriesCpamInvoiceForm
                    series={series}
                    prescription={prescriptionForm}
                    dispatch={dispatch}
                    patientForm={patientForm}
                    seriesCpamInvoiceForm={seriesCpamInvoiceForm}
                    prsDate={prsDateInput}
                    priorApprovalDate={priorApprovalDate}
                    dateMaternity={dateMaternity}
                    workAccidentDate={workAccidentDate}
                />
            ),
            disabled: !isUserAbleToInvoice,
        },
    ]

    return (
        <>
            {series.loading ? (
                <Loader />
            ) : (
                <div className={'container mx-auto pt-2'}>
                    <div className="flex justify-between border-b lg:justify-normal">
                        {tabs.map((tab) => (
                            <button
                                key={tab.name}
                                onClick={() => {
                                    if (
                                        (tab.name === 'Facture' && !id) ||
                                        (tab.name === 'Patient' &&
                                            !patientForm['@id']) ||
                                        tab.disabled
                                    ) {
                                        return
                                    }
                                    setActiveTab(tab.name)
                                }}
                                className={`tab-btn flex items-center ${
                                    activeTab === tab.name ? 'tab-active' : ''
                                } ${
                                    (tab.name === 'Facture' && !id) ||
                                    (tab.name === 'Patient' &&
                                        !patientForm['@id']) ||
                                    tab.disabled
                                        ? 'tab-disabled'
                                        : ''
                                }`}
                            >
                                <span> {tab.icon}</span>

                                <span className={'hidden lg:block'}>
                                    {tab.name}
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className={'pt-10'}>
                        {tabs
                            .filter((tab) => tab.name === activeTab)
                            .map((tab) => (
                                <div key={tab.name}>{tab.component}</div>
                            ))}
                    </div>
                    <div className="mt-auto flex w-full justify-between border-t px-2 py-5 lg:px-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            hiddenLabelOnMobile={false}
                            disabled={series.loading}
                            loader={series.loading}
                            action={handleCancel}
                        />
                        {activeTab !== 'Facture' ? (
                            <PrimaryButton
                                label={
                                    !series.id
                                        ? 'Créer la série'
                                        : 'Modifier la série'
                                }
                                title={
                                    !series.id
                                        ? 'Créer la série'
                                        : 'Modifier la série'
                                }
                                loader={series.disableButtons}
                                disabled={series.disableButtons}
                                action={handleForm}
                            />
                        ) : null}
                    </div>

                    {series.openValidationModal && (
                        <ActionSeriesModal
                            setModalOpen={() =>
                                dispatch({
                                    type: SERIES_ACTIONS.SET_OPEN_VALIDATION_MODAL,
                                    payload: false,
                                })
                            }
                            warningLabel="Vérifiez vos changements avant de valider."
                            label="Les changements pourraient impacter des transports validés ou partagés."
                            action={handleCreateOrPutSeries}
                            labelAction="Modification de la série"
                            actionButton="Valider les changements"
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default SeriesForm
