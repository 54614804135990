import { API_ENTRYPOINT } from '../config/entrypoint'
import * as dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
    handleApiError,
    handleApiErrorWithoutJsonResponse,
    handleDeleteApiError,
} from '../tools/Errors'
import AsyncLocalStorage from '@createnextapp/async-local-storage'
import { toast } from 'react-toastify'
import { addEntityFilter } from './API/pathsAndFilters'
import { formatDateToApi, formatDateYYYYMMDD } from './dateUtils'

dayjs.extend(dayjsPluginUTC)

let token = localStorage.getItem('token')

export const getHeaders = async (isJsonLd = false) => {
    let token
    try {
        token = await AsyncLocalStorage.getItem('@token')
        localStorage.setItem('token', token)
    } catch (e) {
        console.error('Error retrieving token:', e)
        // Handle the error appropriately, possibly re-throwing it or returning a default value
    }

    return {
        'Content-Type': isJsonLd ? 'application/ld+json' : 'application/json',
        Authorization: `Bearer ${token}`,
    }
}

export const formatHydraData = (data) => {
    if (data['hydra:member']) {
        return data['hydra:member']
    }

    return data
}

export const formatHydraDataWithPagination = (data) => {
    if (data['hydra:member']) {
        return {
            data: data['hydra:member'],
            totalItems: data['hydra:totalItems'],
            page: data['hydra:page'],
        }
    }

    return data
}

export const concat = (path, param) => {
    if (path.includes('?')) path += '&' + param
    else path += '?' + param

    return path
}

const API = {
    Authentication: {
        handleLogin: async (email, password) => {
            return fetch(API_ENTRYPOINT + '/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: email,
                    password: password,
                }),
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        getUser: async () => {
            return fetch(API_ENTRYPOINT + '/me', {
                method: 'GET',
                headers: await getHeaders(),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        getUserForRegistration: async (token) => {
            return fetch(API_ENTRYPOINT + '/registration/' + token, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json+ld',
                },
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        handleRegistration: async (token, password) => {
            return fetch(API_ENTRYPOINT + '/registration/' + token, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: password,
                }),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        handleSignUpUser: async (data) => {
            return fetch(API_ENTRYPOINT + '/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        handleSignUpSociety: async (id, data) => {
            return fetch(API_ENTRYPOINT + `/register/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        handleSocietyPlan: async (id, data) => {
            return fetch(API_ENTRYPOINT + `/register/plan/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        completeInscriptionFreePlan: async (id) => {
            return fetch(API_ENTRYPOINT + `/register/plan/free/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        handleFreeSignup: async (id, data) => {
            return fetch(API_ENTRYPOINT + `/register/free/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
    },
    Society: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/societies/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/societies/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
    },
    Stripe: {
        CreateCheckout: async (data, subscription) => {
            return fetch(
                API_ENTRYPOINT +
                    `${
                        subscription
                            ? '/stripe/checkout'
                            : '/stripe/checkout/sms'
                    }`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        Authorization: `Bearer ${localStorage.getItem(
                            'token'
                        )}`,
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        listProducts: async () => {
            let path = '/sales/stripe/products'

            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    window.location.href = '/' // redirect
                })
        },
        CreateContratAndAccount: async (data) => {
            return fetch(API_ENTRYPOINT + '/sales/contracts', {
                method: 'POST',
                headers: await getHeaders(true),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        CreateCustomerAndPaymentIntent: async (data) => {
            return fetch(API_ENTRYPOINT + '/sales/paymentIntent', {
                method: 'POST',
                headers: await getHeaders(true),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
    },
    Runs: {
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/runs/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            }).then(handleDeleteApiError)
        },
        listForSeriesManage: async (seriesId) => {
            let path = '/runs_for_series_manage'
            if (seriesId) path = concat(path, 'series=' + seriesId)

            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        listWithSeriesId: async (seriesId) => {
            let path = '/runs'

            if (seriesId) path = concat(path, 'series=' + seriesId)

            path = concat(path, 'pagination=false')

            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (
            search,
            date,
            pickUpLocation,
            depositLocation,
            patient,
            team,
            currentPage,
            itemsPerPage,
            existsMasterRun,
            pagination,
            departureTime,
            arrivingTime,
            between,
            type,
            excludeIds,
            existsSeries,
            seriesId,
            seriesFirstRunDate,
            existsStatus,
            status,
            object,
            user,
            partner,
            existsPartner,
            isGiven,
            customFilter,
            excludeGivenRuns,
            excludeStatus,
            cpamInvoiceId
        ) => {
            let path = '/runs'
            //TODO add hour search
            if (team)
                path = concat(path, 'masterRun=' + team.split('/').reverse()[0])

            if (pickUpLocation)
                path = concat(path, 'pickUpLocation.city=' + pickUpLocation)

            if (depositLocation)
                path = concat(path, 'depositLocation.city=' + depositLocation)

            if (patient) {
                path = addEntityFilter(path, patient, 'patient')
            }
            if (partner)
                path = concat(
                    path,
                    'partner=' + partner.split('/').reverse()[0]
                )
            if (date) path = concat(path, 'date=' + formatDateYYYYMMDD(date))

            if (between)
                path = concat(
                    path,
                    'date[before]=' +
                        between.strictlyBefore +
                        '&date[after]=' +
                        between.strictlyAfter
                )

            if (type)
                path = concat(path, 'type=' + type.split('/').reverse()[0])

            if (departureTime)
                path = concat(
                    path,
                    'departureTime[after]=' +
                        formatDateToApi(departureTime, true)
                )

            if (arrivingTime)
                path = concat(
                    path,
                    'arrivingTime[before]=' +
                        formatDateToApi(arrivingTime, true)
                )

            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (itemsPerPage)
                path = concat(path, 'itemsPerPage=' + itemsPerPage)

            if (search) path = concat(path, 'search=' + search)

            if (existsMasterRun != null)
                path = concat(path, 'exists[masterRun]=' + existsMasterRun)

            if (existsStatus != null)
                path = concat(path, 'exists[status]=' + existsStatus)

            if (status) path = concat(path, 'status=' + status)

            if (user)
                path = concat(
                    path,
                    'userFilter=' + user.split('/').reverse()[0]
                )

            if (excludeIds && excludeIds.length > 0)
                path = concat(
                    path,
                    '&excludeId=' +
                        excludeIds.toString().replace('[', '').replace(']', '')
                )

            if (existsSeries) {
                path = concat(path, 'exists[series]=' + existsSeries)
            }

            if (existsPartner != null) {
                path = concat(path, 'exists[partner]=' + existsPartner)
            }
            if (customFilter) {
                path = concat(path, customFilter)
            }
            if (excludeGivenRuns != null) {
                path = concat(path, 'exists[sharingOption]=' + excludeGivenRuns)
            }
            if (excludeStatus != null) {
                if (excludeStatus !== 'charged and canceled') {
                    path = concat(path, 'excludeStatus=' + excludeStatus)
                } else {
                    path = concat(path, 'excludeStatusAndCanceled=charged')
                }
            }

            if (seriesId) path = concat(path, 'series=' + seriesId)

            if (seriesFirstRunDate)
                path = concat(
                    path,
                    'date[strictly_after]=' +
                        dayjs(seriesFirstRunDate)
                            .add(-1, 'day')
                            .format('YYYY-MM-DD')
                )

            if (typeof pagination === 'boolean')
                path = concat(path, 'pagination=' + pagination)

            if (typeof isGiven === 'boolean')
                path = concat(path, 'isGiven=' + isGiven)

            if (object) {
                path = concat(
                    path,
                    'runObject=' + object.split('/').reverse()[0]
                )
            }

            if (cpamInvoiceId) {
                path = concat(path, 'cpamInvoice=' + cpamInvoiceId)
            }
            const headers = await getHeaders(true)

            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: headers,
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        unassignedRuns: async (date, isPatient) => {
            let path = '/unassigned_runs'
            if (date) path = concat(path, 'date=' + formatDateYYYYMMDD(date))
            if (isPatient !== null)
                path = concat(path, 'isPatient=' + isPatient)
            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addRun: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/runs', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        get: async (id) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/runs/' + id, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        put: async (id, data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/runs/' + id, {
                method: 'PUT',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        notifyContacts: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/notify/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        share: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/share_run/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    DefaultMasterRuns: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/default_master_runs/' +
                    id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/default_master_runs/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search) => {
            let path = '/default_master_runs'

            if (search) path = concat(path, 'search=' + search)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/default_master_runs',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/default_master_runs/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        putPositions: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/default_master_runs_positions',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        reset: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/default_master_runs_reset',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },

        postFromWelcomeModal: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/post_default_master_runs_from_welcome_modal',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        putFromWelcomeModal: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/put_default_master_runs_from_welcome_modal/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    MasterRuns: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/master_runs/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            }).then(handleApiErrorWithoutJsonResponse)
        },
        list: async (
            search,
            date,
            currentPage,
            pagination,
            strictSpecificDate,
            atDate,
            user,
            afterDate,
            itemsPerPage
        ) => {
            let path = '/master_runs'
            if (atDate)
                path = concat(path, 'date=' + formatDateYYYYMMDD(atDate))
            if (user) path = concat(path, 'users=' + user)

            if (search) path = concat(path, 'search=' + search)

            if (date) path = concat(path, 'date=' + formatDateYYYYMMDD(date))
            if (strictSpecificDate)
                path = concat(
                    path,
                    'date%5Bstrictly_before%5D=' +
                        formatDateYYYYMMDD(strictSpecificDate.strictlyBefore) +
                        '&date%5Bstrictly_after%5D=' +
                        formatDateYYYYMMDD(strictSpecificDate.strictlyAfter)
                )
            if (afterDate)
                path = concat(
                    path,
                    'date[after]=' + dayjs(afterDate).format('YYYY-MM-DD')
                )
            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (itemsPerPage)
                path = concat(path, 'itemsPerPage=' + itemsPerPage)

            if (typeof pagination === 'boolean')
                path = concat(path, 'pagination=' + pagination)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        putPositions: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs_positions',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        putHided: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs_hiding',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        reset: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs_reset',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        protectOrUnlock: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/master_runs_protected',
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    Vehicles: {
        list: async (
            vehicleType,
            currentPage,
            pagination,
            search,
            itemsPerPage
        ) => {
            let path = '/vehicles'

            if (vehicleType) path = concat(path, 'type=' + vehicleType)

            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (search) path = concat(path, 'search=' + search)

            if (typeof pagination === 'boolean')
                path = concat(path, 'pagination=' + pagination)

            if (itemsPerPage)
                path = concat(path, 'itemsPerPage=' + itemsPerPage)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/vehicles/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/vehicles/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addVehicle: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/vehicles', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/vehicles/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
    },
    Patients: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/patients/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/patients/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        list: async (search, currentPage, pagination, itemsPerPage) => {
            let path = '/patients'
            if (search) path = concat(path, 'search=' + search)

            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (itemsPerPage)
                path = concat(path, 'itemsPerPage=' + itemsPerPage)

            if (typeof pagination === 'boolean')
                path = concat(path, 'pagination=' + pagination)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addPatient: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/patients', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/patients/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addBeneficiaries: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/patients/add_beneficiaries/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Partners: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/partners/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        Access: async (token, startDate, endDate) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/identify/' +
                    token +
                    '/' +
                    startDate +
                    '&' +
                    endDate || null,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 400) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        getRun: async (token, id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/identify/' +
                    token +
                    '/run/' +
                    id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        putRun: async (token, id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/identify/' +
                    token +
                    '/run_edit/' +
                    id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/partners/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        list: async (search, currentPage, pagination) => {
            let path = '/partners'
            if (search) path = concat(path, 'search=' + search)

            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (typeof pagination === 'boolean')
                path = concat(path, 'pagination=' + pagination)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addPartner: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/partners', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/partners/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    Users: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/users/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        getWithoutSociety: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/register/users/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        putWithoutSociety: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/register/users/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    return Promise.reject(error)
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/users/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (
            search,
            role,
            properties,
            currentPage,
            active,
            noPagination,
            defaultVehicle
        ) => {
            let path = '/users'
            if (search) {
                path = concat(path, 'search=' + search)
            }

            if (role) {
                path = concat(path, 'roles=' + role)
            }

            if (properties) {
                properties.forEach((property) => {
                    path = concat(path, 'properties%5B%5D=' + property)
                })
            }

            if (currentPage) path = concat(path, 'page=' + currentPage)

            if (active) path = concat(path, 'active=' + active)

            if (noPagination) path = concat(path, 'pagination=' + !noPagination)

            if (defaultVehicle)
                path = concat(path, 'exists[defaultVehicle]=' + defaultVehicle)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addUser: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/users', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then(async (response) => {
                    if (response.ok) {
                        return response
                    } else {
                        const errorData = await response.json()
                        const violations = errorData['violations']
                        const emailViolation = violations.find(
                            (violation) => violation['propertyPath'] === 'email'
                        )
                        if (emailViolation) {
                            toast.error(
                                'Cet email est déjà utilisé, veuillez en choisir un autre.'
                            )
                        }
                        const phoneNumberViolation = violations.find(
                            (violation) =>
                                violation['propertyPath'] === 'phoneNumber'
                        )
                        if (phoneNumberViolation) {
                            toast.error(
                                'Ce numéro de téléphone est déjà utilisé, veuillez en choisir un autre.'
                            )
                        }
                        throw new Error(violations)
                    }
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/users/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
    },
    Planning: {
        generate: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/generate_async',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
        setUp: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/generate/setUp',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                    return Promise.reject(error)
                })
        },
    },
    Establishments: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/establishments/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/establishments/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search, currentPage) => {
            let path = '/establishments'
            if (search) path = concat(path, 'search=' + search)

            // if (currentPage) path = concat(path, 'page=' + currentPage)
            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/establishments/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        addEstablishment: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/establishments',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Types: {
        list: async (label) => {
            let path = '/types'

            if (label) path = concat(path, 'label=' + label)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Status: {
        list: async (label) => {
            let path = '/statuses'

            if (label) path = concat(path, 'label=' + label)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    RunObject: {
        list: async (label) => {
            let path = '/run_objects?pagination=false'

            // if (label) path = concat(path, 'label=' + label)

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
    },
    PlanningViews: {
        list: async () => {
            let path = '/planning_views/'

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Parameter: {
        get: async (society) => {
            society = society.split('/').reverse()[0]
            let path = '/parameters/' + society
            const headers = await getHeaders(true)
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: headers,
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }

                    return Promise.reject(error)
                })
        },
        put: async (society, data) => {
            society = society.split('/').reverse()[0]
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/parameters/' + society,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }
                return Promise.reject(response)
            })
        },
    },
    Pdf: {
        planning: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/planning_pdf',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        invoice: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/free_invoice/create',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        downloadInvoice: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/free_invoice/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
    },
    Series: {
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/series/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(false),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        listByPatient: async (patientId, newSeries) => {
            let path = '/series'

            if (patientId) path = concat(path, 'patient=' + patientId)
            if (newSeries) path = concat(path, 'newSeries=' + newSeries)

            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search, pagination, page, archived) => {
            let path = '/series'

            if (search) path = concat(path, 'search=' + search)

            if (page) path = concat(path, 'page=' + page)

            if (archived !== null) path = concat(path, 'archived=' + archived)

            // TODO ON VERRA SI UN CLIENT SE PLAINDRA DE PAS VOIR SES ANCIENNES SERIES
            path = concat(path, 'newSeries=true&pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        post: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/series', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/series/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        delete: async (id) => {
            return fetch(API_ENTRYPOINT + '/series/' + id, {
                method: 'DELETE',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        checkTeamsRunOnSameHours: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/series_prevent_teams_run_on_same_hours',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    Plans: {
        get: async (label) => {
            let path = '/plans'

            if (label) path = concat(path, 'label=' + label)

            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
    },
    Invoices: {
        list: async (search, between, archived) => {
            let path = '/invoices'

            if (search) path = concat(path, 'search=' + search)

            if (between)
                path = concat(
                    path,
                    'date[before]=' +
                        between.strictlyBefore +
                        '&date[after]=' +
                        between.strictlyAfter
                )
            if (typeof archived === 'boolean')
                path = concat(path, 'archived=' + archived)

            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/invoices/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/invoices/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Export: {
        getRuns: async (
            search,
            date,
            patient,
            between,
            type,
            status,
            object,
            user,
            partner,
            excludeStatus
        ) => {
            let path = '/runs/export'

            if (patient)
                path = concat(
                    path,
                    'patient=' + patient.split('/').reverse()[0]
                )
            if (partner)
                path = concat(
                    path,
                    'partner=' + partner.split('/').reverse()[0]
                )
            if (date) path = concat(path, 'date=' + formatDateYYYYMMDD(date))

            if (between)
                path = concat(
                    path,
                    'date[before]=' +
                        between.strictlyBefore +
                        '&date[after]=' +
                        between.strictlyAfter
                )

            if (type)
                path = concat(path, 'type=' + type.split('/').reverse()[0])

            if (search) path = concat(path, 'search=' + search)

            if (status) path = concat(path, 'status=' + status)

            if (user)
                path = concat(
                    path,
                    'masterRun.users=' + user.split('/').reverse()[0]
                )

            if (object) {
                path = concat(
                    path,
                    'runObject=' + object.split('/').reverse()[0]
                )
            }

            if (excludeStatus != null) {
                if (excludeStatus !== 'charged and canceled') {
                    path = concat(path, 'excludeStatus=' + excludeStatus)
                } else {
                    path = concat(path, 'excludeStatusAndCanceled=charged')
                }
            }
            return fetch(API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            }).then((response) => {
                if (response.ok) {
                    return response
                }

                return Promise.reject(response)
            })
        },
    },
    Transfer: {
        addTransports: async (data) => {
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + '/transfer', {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }

                    return Promise.reject(error)
                })
        },
    },
    Notifications: {
        list: async () => {
            const headers = await getHeaders(true)
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/notifications',
                {
                    method: 'GET',
                    headers: headers,
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/notifications/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Distance: {
        get: async (start, finish) => {
            let path = `/distances?start=${start}&finish=${finish}`

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Pec: {
        post: async (data) => {
            let path = `/pec`

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify(data),
            }).then((response) => {
                if (response.ok) {
                    return response
                }
                return Promise.reject(response)
            })
        },
    },
    payingCenter: {
        get: async () => {
            let path = `/paying_centers?pagination=false`

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search) => {
            let path = `/paying_centers?pagination=false`
            if (search) path = concat(path, 'search=' + search)
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/paying_centers/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/paying_centers',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    Practitioner: {
        get: async () => {
            let path = `/practitioners`
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/practitioners',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/practitioners/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search) => {
            let path = `/practitioners?pagination=false`
            if (search) path = concat(path, 'search=' + search)
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
    CpamInvoice: {
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/cpam_invoices',
                {
                    method: 'POST',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/cpam_invoices/' + id,
                {
                    method: 'PUT',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        list: async (
            search,
            between,
            archived,
            payingCenter,
            existCpamInvoiceGroup
        ) => {
            let path = '/cpam_invoices'
            if (search) path = concat(path, 'search=' + search)

            if (payingCenter)
                path = concat(
                    path,
                    'payingCenter=' + payingCenter.split('/').reverse()[0]
                )

            if (between)
                path = concat(
                    path,
                    'date[before]=' +
                        between.strictlyBefore +
                        '&date[after]=' +
                        between.strictlyAfter
                )

            if (typeof existCpamInvoiceGroup === 'boolean')
                path = concat(
                    path,
                    'exists[cpamInvoiceGroup]=' + existCpamInvoiceGroup
                )

            if (archived) {
                path = concat(path, 'statusFilter=Cloturée')
            } else {
                path = concat(path, 'excludeStatus=Cloturée')
            }

            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/cpam_invoices/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        print: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/print_invoice_cpam/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        printPatientLetter: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/cpam_invoice_patient_request/' +
                    id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        suspend: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/handle_cpam_invoice_status_suspend/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
        changeStatuses: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/handle_cpam_invoice_statuses/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(true),
                    body: JSON.stringify(data),
                }
            )
                .then(handleApiError)
                .then((data) => {
                    return data
                })
        },
    },
    CpamInvoiceGroup: {
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/cpam_invoice_groups',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/cpam_invoice_groups/' +
                    id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }
                return Promise.reject(response)
            })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/cpam_invoice_groups/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        list: async (search, between, archived) => {
            let path = '/cpam_invoice_groups'

            if (search) path = concat(path, 'search=' + search)

            if (between)
                path = concat(
                    path,
                    'date[before]=' +
                        between.strictlyBefore +
                        '&date[after]=' +
                        between.strictlyAfter
                )
            if (typeof archived === 'boolean')
                path = concat(path, 'archived=' + archived)

            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }

                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        transfer: async (transferGroup) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/cpam_invoice_group_transfer',
                {
                    method: 'POST',
                    body: JSON.stringify(transferGroup),
                    headers: await getHeaders(true),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }
                return Promise.reject(response)
            })
        },
    },
    Noemie: {
        list: async (archived) => {
            let path = '/b2_files'

            if (archived !== null) path = concat(path, 'archived=' + archived)

            path = concat(path, 'pagination=false')

            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        get: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/b2_files/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        print: async (id) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/pdf_cpam/' + id,
                {
                    method: 'GET',
                    headers: await getHeaders(false),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        receivedPayment: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/noemie/received_payment',
                {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        resolveRejectedInvoices: async (lotId) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/resolve_rejected_invoices/' +
                    lotId,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        close: async (lotId) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT + '/noemie/close/' + lotId,
                {
                    method: 'GET',
                    headers: await getHeaders(true),
                }
            ).then((response) => {
                if (response.ok) {
                    return response
                }
                return Promise.reject(response)
            })
        },
    },
    HealthComplementary: {
        list: async () => {
            let path = '/health_complementaries?pagination=false'
            return fetch(process.env.REACT_APP_API_ENTRYPOINT + path, {
                method: 'GET',
                headers: await getHeaders(true),
            })
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        post: async (data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/health_complementaries',
                {
                    method: 'POST',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
        put: async (id, data) => {
            return fetch(
                process.env.REACT_APP_API_ENTRYPOINT +
                    '/health_complementaries/' +
                    id,
                {
                    method: 'PUT',
                    headers: await getHeaders(),
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response
                    }
                    return Promise.reject(response)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/' // redirect
                    }
                })
        },
    },
}

export default API
