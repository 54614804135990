import Error from '../components/Commons/Error'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'

export const formatSavedAddresses = (savedAddresses) => {
    return savedAddresses
        .filter((address) => !address.new)
        .map((addressInfo) => {
            return {
                ...addressInfo,
                ...addressInfo.address?.googlePrediction.value,
                description: addressInfo.address?.googlePrediction.label,
            }
        })
}

export const openMaps = (address) => {
    if (
        /Android/i.test(navigator.userAgent) ||
        /iPhone/i.test(navigator.userAgent) ||
        /iPad/i.test(navigator.userAgent)
    )
        window.open(
            `waze://?q=${
                address.street ? address.street.replace(' ', '+') + ',' : ''
            }${address.zipCode},${address.city.replace(' ', '+')}`
        )
    else
        window.open(
            `https://maps.google.com/?q=${
                address.street ? address.street.replace(' ', '+') + ',' : ''
            }${address.zipCode},${address.city.replace(' ', '+')}`
        )
}

const ADDRESS_GPT3 = {
    GoogleFormat: (address) => {
        // Create a map of address types to short names
        const shortNames = new Map(
            address.map((a) => [a.types[0], a.short_name])
        )

        const longNames = new Map(address.map((a) => [a.types[0], a.long_name]))
        // Use the map to lookup short names for each address type
        const streetNumber = shortNames.get('street_number') || ''
        const street = shortNames.get('route') || ''
        const neighborhood = shortNames.get('neighborhood') || ''
        const finalStreet = street
            ? `${streetNumber ? streetNumber : ''} ${street}`
            : neighborhood
        const zipCode = shortNames.get('postal_code') || ''
        const city = shortNames.get('locality') || ''
        const county = longNames.get('administrative_area_level_2') || ''
        const country = longNames.get('country') || ''
        const countryCode = shortNames.get('country') || ''
        const administrative =
            shortNames.get('administrative_area_level_1') || ''

        return {
            finalStreet: finalStreet !== ' ' ? finalStreet : '',
            zipCode: zipCode,
            city: city,
            county: county,
            country: country,
            countryCode: countryCode,
            administrative: administrative,
        }
    },
}

export const getAddressInfo = async (googlePrediction) => {
    // Use async/await to simplify the code and improve readability
    try {
        const results = await geocodeByPlaceId(googlePrediction.placeId)
        const formatAddress = ADDRESS_GPT3.GoogleFormat(
            results[0].address_components
        )

        let newLocation = {
            city: formatAddress.city,
            country: formatAddress.country,
            street: formatAddress.finalStreet,
            zipCode: formatAddress.zipCode,
            countryCode: formatAddress.countryCode,
            administrative: formatAddress.administrative,
            county: formatAddress.county,
            secondaryStreet: '',
            label: (() => {
                const keywords = [
                    'gare',
                    'hopital',
                    'clinique',
                    'centre',
                    'cabinet',
                    'laboratoire',
                    'pharmacie',
                ]

                // Fonction pour normaliser le texte (supprime les accents et met en minuscules)
                const normalize = (text) =>
                    text
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()

                const parts = googlePrediction.description.split(',')

                if (parts.length > 0) {
                    const firstPart = parts[0].trim()
                    const normalizedFirstPart = normalize(firstPart)

                    if (
                        keywords.some((keyword) =>
                            normalizedFirstPart.includes(normalize(keyword))
                        )
                    ) {
                        return firstPart // Retourne la première partie si elle contient un mot-clé
                    }
                }

                return '' // Retourne une chaîne vide si aucun mot-clé n'est trouvé dans la première partie
            })(),
            googlePrediction: {
                label: googlePrediction.description,
                value: googlePrediction,
            },
            coordinates: results[0].geometry.location.toJSON(),
        }

        return newLocation
    } catch (error) {
        throw new Error()
    }
}

export const formatAddressInfo = async (geoapifyFeature, firstCall = true) => {
    const props = geoapifyFeature.properties || geoapifyFeature.address
    let detailedProps = props
    if (props.place_id && !firstCall) {
        try {
            const apiKey = '45ee1b1f4ec649caab1011e60d157f2c' // Remplacez par votre clé API réelle
            const response = await fetch(
                `https://api.geoapify.com/v2/place-details?id=${props.place_id}&features=details,details.names&apiKey=${apiKey}`
            )

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            detailedProps = { ...props, ...data.features[0].properties }
        } catch (error) {
            console.error('Error fetching place details:', error)
        }
    }

    // Vérifier si address_line1 contient le code postal et la ville
    const isAddressLineCombination =
        detailedProps.address_line1 &&
        detailedProps.postcode &&
        detailedProps.city &&
        detailedProps.address_line1.includes(detailedProps.postcode) &&
        detailedProps.address_line1.includes(detailedProps.city)

    // Si address_line1 contient la ville
    const isAddressLineCity =
        detailedProps.address_line1 &&
        detailedProps.city &&
        detailedProps.address_line1 === detailedProps.city

    return {
        city: detailedProps.city || '',
        country: detailedProps.country || '',
        codeInsee:
            detailedProps.codeInsee ||
            detailedProps.ref_other?.insee.toString() ||
            '',
        street:
            (detailedProps.street
                ? `${
                      detailedProps.housenumber
                          ? detailedProps.housenumber + ' '
                          : ''
                  }${detailedProps.street}`
                : isAddressLineCombination || isAddressLineCity
                ? ''
                : detailedProps.address_line1) || '',
        zipCode:
            detailedProps.zipCode ||
            detailedProps.postcode ||
            (detailedProps.department_COG
                ? detailedProps.department_COG + '000'
                : ''),
        countryCode: detailedProps.country_code || '',
        administrative: detailedProps.state || '',
        county: detailedProps.county || '',
        secondaryStreet: detailedProps.secondaryStreet || '',
        label: (() => {
            if (detailedProps.label) {
                return detailedProps.label
            }
            if (
                detailedProps.name &&
                detailedProps.name !== detailedProps.street
            ) {
                // si la ville est présente dans le name on la retire
                if (
                    detailedProps.city &&
                    detailedProps.name.includes(detailedProps.city)
                ) {
                    return detailedProps.name.replace(detailedProps.city, '')
                }
                return detailedProps.name
            }
        })(),
        coordinates: {
            lat: detailedProps.lat,
            lng: detailedProps.lon,
        },
    }
}
