import { apiClient } from '../apiClient'
import { formatHydraData } from '../../api'
import { addEntityFilter, concat } from '../pathsAndFilters'

const PATH_KEY = '/cpam_invoices'

export const CpamInvoice = {
    fetchAll: async (
        search,
        status,
        archived,
        payingCenter,
        healthComplementary,
        patient,
        outstandingBalance
    ) => {
        let path = PATH_KEY + '?pagination=false'
        if (search) path += `&search=${search}`
        if (status) path = addEntityFilter(path, status, 'statusFilter')
        if (payingCenter)
            path = addEntityFilter(path, payingCenter, 'payingCenter')
        if (healthComplementary)
            path = addEntityFilter(
                path,
                healthComplementary,
                'healthComplementary'
            )
        if (patient) {
            path = addEntityFilter(path, patient, 'patient')
        }
        if (!status) {
            if (archived) {
                path += '&statusFilter=Cloturée'
            } else {
                path = concat(path, 'excludeStatus=Cloturée')
            }
        }
        if (outstandingBalance) {
            path +=
                '&healthComplementaryStatus=En attente&payingCenterStatus=Virement reçu&rateFilter=55'
        }
        return formatHydraData(await apiClient.get(path))
    },
}
