import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import dayjs from 'dayjs'
import RegulationListContainer from './RegulationListContainer'
import Loader from '../../Commons/Loaders/Loader'
import { SortRunsByGroupAndDepartureTime } from '../../../tools/Sort'
import { toast } from 'react-toastify'
import { useDebounce } from '../../../hooks/useDebounce'
import { formatTimeLocal } from '../../../services/dateUtils'
import RUN from '../../../services/run'

const formatData = (runs) => {
    return SortRunsByGroupAndDepartureTime(
        runs.map((run) => {
            const startHour = formatTimeLocal(run.departureTime)
            const endHour = formatTimeLocal(run.arrivingTime)
            let patientName = ''
            if (run.patient != null) {
                patientName = `${run.patient.lastname} ${run.patient.firstname}`
            }
            const ride = `${run.pickUpLocation.city} - ${run.depositLocation.city}`
            const address = `${
                run.pickUpLocation.street
                    ? run.pickUpLocation.street + ' - '
                    : ''
            }${run.pickUpLocation.zipCode ? run.pickUpLocation.zipCode : ''} ${
                run.pickUpLocation.city
            }`
            let team = ''
            if (run.masterRun != null) {
                run.masterRun.users.forEach((user) => {
                    if (team !== '') team += ' - ' + user.lastname
                    else team = user.lastname
                })
            }

            return {
                ...run,
                fullname: patientName,
                heure: startHour + ' - ' + endHour,
                ride: ride,
                address: address,
                team: team,
            }
        })
    )
}

const Regulation = ({
    user,
    search,
    dateFrom,
    dateTo,
    type,
    page,
    setPage,
    seriesId,
    seriesFirstRunDate,
    patient,
    team,
    object,
    handleErase,
    setAction,
    action,
    setFirstLoading,
    firstLoading,
    status,
    isSocietyTaxi,
    partner,
    shareFilter,
    openConfiguratorModal,
    setOpenConfiguratorModal,
    setAddToTransferGroup,
    transferGroup,
    selectAll,
    setSelectAll,
    excludeStatus,
    setRefreshing,
}) => {
    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)

    const [lastPage, setLastPage] = useState()
    const [computerConfigurator, setComputerConfigurator] = useState({})
    const [mobileConfigurator, setMobileConfigurator] = useState({})

    function fetchRunsList() {
        if (rows.length === 0) {
            setFirstLoading(true)
        }
        const between = {
            strictlyBefore: dayjs(dateTo).format('YYYY-MM-DD'),
            strictlyAfter: dayjs(dateFrom).format('YYYY-MM-DD'),
        }
        if (seriesId) {
            setLoading(true)
            API.Runs.list(
                search,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                false,
                null,
                null,
                null,
                null,
                null,
                null,
                seriesId
            ).then((data) => {
                setRows(formatData(data['hydra:member']))
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
                setRefreshing(false)
            })
        } else {
            setLoading(true)
            API.Runs.list(
                search,
                null,
                null,
                null,
                patient != null ? patient.value : '',
                null,
                page,
                100,
                null,
                null,
                null,
                null,
                between,
                type != null ? type.value : null,
                null,
                null,
                null,
                null,
                null,
                status != null ? status.value : null,
                object != null ? object.value : null,
                user != null ? user.value : null,
                partner != null ? partner.value : null,
                null,
                null,
                shareFilter != null ? shareFilter.filtre : null,
                null,
                excludeStatus != null ? excludeStatus.value : null
            ).then((data) => {
                const rowsFormated = formatData(data['hydra:member'])
                setRows(rowsFormated)
                const dataPage = data['hydra:view']
                    ? data['hydra:view']['hydra:last']
                        ? parseInt(
                              data['hydra:view']['hydra:last']
                                  .split('=')
                                  .reverse()[0]
                          )
                        : 1
                    : 1
                setLastPage(dataPage)
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
                setRefreshing(false)
            })
        }
    }

    useEffect(() => {
        fetchRunsList()
    }, [action, page, seriesFirstRunDate, seriesId])

    useDebounce(() => fetchRunsList(), 800, [search])

    const fetchParameters = () => {
        setLoading(true)
        const society = localStorage.getItem('society')
            ? localStorage.getItem('society')
            : null
        if (society) {
            API.Parameter.get(society).then((response) => {
                response.json().then((data) => {
                    setComputerConfigurator(data.computerConfigurator.custom)
                    setMobileConfigurator(data.mobileConfigurator.custom)
                    setLoading(false)
                })
            })
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchParameters()
    }, [])

    const handleDeleteRun = (id) => {
        API.Runs.delete(id)
            .then((response) => {
                if (response.ok) {
                    toast.success('Le transport a bien été supprimé.')
                }
                fetchRunsList()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleShareRun = (id, data) => {
        API.Runs.share(id, data)
            .then((response) => {
                if (response.ok) {
                    toast.success('Le transport a bien été partagé.')
                }
                fetchRunsList()
            })
            .catch(() => {
                toast.error(
                    'La limite de partage de transport par SMS a été dépassé.'
                )
            })
    }

    const handleRemoveShare = async (id) => {
        setLoading(true)
        id = parseInt(id)
        const responseRemoveShare = await RUN.RemoveShare(id)
        fetchRunsList()
    }

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : rows.length > 0 ? (
                <RegulationListContainer
                    rows={rows}
                    setPage={setPage}
                    totalItems={totalItems}
                    loading={loading}
                    handleDeleteElement={handleDeleteRun}
                    page={page}
                    lastPage={lastPage}
                    isSocietyTaxi={isSocietyTaxi}
                    handleShareRun={handleShareRun}
                    handleRemoveShare={handleRemoveShare}
                    openConfiguratorModal={openConfiguratorModal}
                    setOpenConfiguratorModal={setOpenConfiguratorModal}
                    computerConfigurator={computerConfigurator}
                    mobileConfigurator={mobileConfigurator}
                    setComputerConfigurator={setComputerConfigurator}
                    transferGroup={transferGroup}
                    setAddToTransferGroup={setAddToTransferGroup}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    fetchRunsList={fetchRunsList}
                />
            ) : (
                <NoData
                    message="Aucune prise en charge disponible"
                    labelAction={'Effacer les filtres'}
                    handleAction={handleErase}
                    setAction={setAction}
                    action={action}
                    add={true}
                    icon={'fa-ambulance'}
                    link={'/transport/0'}
                />
            )}
        </div>
    )
}

export default Regulation
