import { toast } from 'react-toastify'
import { apiClient, handleApiClientError } from '../apiClient'
import { formatHydraData } from '../../api'

const PATH_KEY = '/practitioners'

export const Practitioners = {
    post: async (practitionerData) => {
        if (checkPractitionerForm(practitionerData)) {
            throw new Error('Form validation failed')
        }
        try {
            const response = await apiClient.post(PATH_KEY, practitionerData)
            toast.success('Praticien ajouté avec succès')
            return formatHydraData(response)
        } catch (error) {
            handleApiClientError(error)
            throw error
        }
    },
    fetchAll: async (search) => {
        let path = PATH_KEY
        if (search) path = `${path}?search=${search}`
        return formatHydraData(await apiClient.get(path))
    },
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
    put: async (id, practitionerData) => {
        if (checkPractitionerForm(practitionerData)) {
            throw new Error('Form validation failed')
        }
        try {
            const response = await apiClient.put(
                `${PATH_KEY}/${id}`,
                practitionerData
            )
            toast.success('Praticien modifié avec succès')
            return formatHydraData(response)
        } catch (error) {
            handleApiClientError(error)
            throw error
        }
    },
}

const checkPractitionerForm = (practitionerForm) => {
    let errors = []
    if (practitionerForm.isEstablishment && !practitionerForm.label) {
        errors.push('Veuillez renseigner un label.')
    } else if (
        !practitionerForm.isEstablishment &&
        !practitionerForm.lastname
    ) {
        errors.push('Veuillez renseigner un nom.')
    }
    if (practitionerForm.numFiness && practitionerForm.numFiness.length !== 9) {
        errors.push('Le numéro FINESS doit comporter 9 caractères.')
    }

    if (errors.length > 0) {
        errors.forEach((e) => toast.error(e))
        return true
    }
    return false
}

export const handlePractitionerForm = async (practitionerForm) => {
    try {
        if (practitionerForm.id) {
            return await Practitioners.put(
                practitionerForm.id,
                practitionerForm
            )
        } else {
            return await Practitioners.post(practitionerForm)
        }
    } catch (error) {
        // L'erreur est déjà gérée dans les fonctions post et put
        throw error
    }
}
