import StyledInput from '../../Commons/StyledInput'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Practitioners } from '../../../services/API/Entities/practitioners'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import NoData from '../NoData'

const PractitionerListComponent = ({ search, setSearch }) => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('')

    const {
        data: practitioners = [],
        isLoading: isLoading,
        isError,
    } = useQuery({
        queryKey: ['practitioners', search],
        queryFn: () => Practitioners.fetchAll(search),
        keepPreviousData: true,
    })

    const filteredPractitioners = practitioners.filter((center) =>
        Object.entries(center).some(([key, value]) => {
            // Ignore les champs id et autres champs non pertinents pour la recherche
            if (key === 'id') return false

            // Vérifier si la valeur est null ou undefined
            if (value == null) return false

            // Convertir en chaîne de caractères et comparer
            return value
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        })
    )

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des caisses." />
        )
    }

    if (practitioners.length === 0)
        return <NoData message="Aucun lot disponible" />

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">
                    Liste de vos prescripteurs
                </h1>
                <div className="flex items-center space-x-4">
                    <StyledInput
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="flex items-center">
                        <p className="mr-1 text-sm text-gray-500">Total</p>
                        <p className="text-sm font-bold text-gray-500">
                            {practitioners.length}
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
                {filteredPractitioners.map((practitioner) => (
                    <div
                        key={practitioner.id}
                        onClick={() => {
                            navigate(
                                `/ressources/prescripteurs/${practitioner.id}`
                            )
                        }}
                        className="cursor-pointer rounded-lg bg-white p-6 shadow-md transition-shadow duration-300 hover:shadow-lg"
                    >
                        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
                            <div className="flex-1">
                                <p className="font-bold">
                                    {practitioner.label
                                        ? practitioner.label
                                        : `${practitioner.firstname} ${practitioner.lastname}`}
                                </p>
                            </div>
                            <div className="flex-1">
                                <p className="text-sm font-medium">
                                    {practitioner.numFiness}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PractitionerListComponent
